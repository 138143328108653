import React, { useState } from 'react';
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle';
import ProjectCard from '../Cards/ProjectCards';
import { projects } from '../../data/constants';


const Projects = ({ openModal, setOpenModal }) => {
  const [toggle, setToggle] = useState('all');


  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Desc>
          I have worked on a wide range of projects. From Software Programs to Planning 3D commercials. Here are some of my projects.
        </Desc>
        <ToggleButtonGroup>
          {toggle === 'all' ? (
            <ToggleButton active value="all" onClick={() => setToggle('all')}>
              All
            </ToggleButton>
          ) : (
            <ToggleButton value="all" onClick={() => setToggle('all')}>
              All
            </ToggleButton>
          )}
          <Divider />
          {toggle === 'Software Applications' ? (
            <ToggleButton active value="Software Applications" onClick={() => setToggle('Software Applications')}>
              Software Applications
            </ToggleButton>
          ) : (
            <ToggleButton value="Software Applications" onClick={() => setToggle('Software Applications')}>
              Software Applications
            </ToggleButton>
          )}
          <Divider />
          {toggle === 'E-commerce' ? (
            <ToggleButton active value="E-commerce" onClick={() => setToggle('E-commerce')}>
              E-commerce
            </ToggleButton>
          ) : (
            <ToggleButton value="E-commerce" onClick={() => setToggle('E-commerce')}>
              E-commerce
            </ToggleButton>
          )}
          <Divider />
          {toggle === 'Cybersecurity' ? (
            <ToggleButton active value="Cybersecurity" onClick={() => setToggle('Cybersecurity')}>
              Cybersecurity
            </ToggleButton>
          ) : (
            <ToggleButton value="Cybersecurity" onClick={() => setToggle('Cybersecurity')}>
              Cybersecurity
            </ToggleButton>
          )}
        </ToggleButtonGroup>
        <CardContainer>
          {(toggle === 'all' ? projects : projects.filter((item) => item.category === toggle) || [])
            .map((project) => (
              <ProjectCard key={project.id} project={project} setOpenModal={setOpenModal} />
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  );
};


export default Projects