export const Bio = {
  name: "Shanice Shipp",
  roles: [
    "Full Stack Developer",
    "Cybersecurity Specialist",
    "E-commerce Solutions Architect",
    "Cryptocurrency Investor",
  ],
  description:
    "I'm a dynamic and spirited individual, always on the lookout for thrilling new challenges to tackle head-on. My relentless passion for learning fuels my drive to consistently deliver outstanding results. Armed with an infectious positivity and an unyielding belief in the power of personal growth, I'm revved up and ready to ignite change. I'm here to embark on an electrifying journey of achievement, charged up to reach new heights of greatness with an insatiable hunger for excellence!",
  github: "https://github.com/TechnoShanice",
  resume:
    "https://docs.google.com/document/d/1MebInvqJ7okm4XIUqYh1ZWs4QcfHUT4s/edit?usp=sharing&ouid=100123798691436901900&rtpof=true&sd=true",
  linkedin: "https://www.linkedin.com/in/jane-s-41457916b?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BGfFjxbJSQ2mYaZJFWJB9VQ%3D%3D",
  twitter: "https://twitter.com",
  insta: "https://www.instagram.com",
  facebook: "https://www.facebook.com",
};


export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "Three JS",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/_D_h6aWq_400x400-removebg-preview.png",
      },
      {
        name: "Next Js",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACTklEQVR4Ab1XAaQqURB9DyohSykREpRIQSAlBCoECKUFCSRCBBEAaSEABQEoCIEASCwAUICALgCo83do0//9v819XX845O7VnDkzOzP7JWGaBd3C3IJpQVjAHeJ+Rs9a97vKLGrBsB1KgMhEP3FMUUwt4ENMfxr1yQIU4SSjRkbeOZtERmHk6pXQVDlnkHh9S+QLTm1hkiz4n/gzFQuny9FoFLquE+i34x+n02k0m00UCoV3BIzn3MMJrVYLtp1OJ0cS/X4f5/MZhmG8IyDsWtDfEaDIn2232/3zbrvdxuFwwGg04qRBt+VnETBNE0IIkE2n07/erdfrWK/X6Ha73Hb9ZXII3G43ivy3dNRqtZe7lUoFs9mM6oBDwCQCgquALT1FT3a5XF7qIZ/PYzgcolqtcggIIgBZAgRKB6lCRalp2uM8k8mAVMrlchwC+DEBipycE4n5fP44j8ViKJVKSCaTbAJCpgaez4vFIsjoWa/XA50FAgEkEgmEw2F2CkxZBZ5Br5tt1ITcbjd8Ph88Hg+7CBefECCsVitS4aVJcV9D/VMCVITk/Hq9YrPZyBBo2a1YMGvAcQYcj0cCtWMugcdYNhjDiBrP25mx3++x3W6RzWZZ8isfxzQLlsslJpMJpYY5jhkqcOH1ejEYDDAej9FoNOByuZxGsfqVzC7KTqcDSkkqleKsZOqX0mAwiHK5DGrJfr+fs5SqX8sjkQji8ThCoRC+v78Za7l6JagrUh3YkUuZpqgwDaecc9VYSDoV5Fg+at7n+eLN57kuE/EvzHr/Kvs31aYAAAAASUVORK5CYII=",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
     
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
      {
        name: "C#",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/c-sharp-c-logo-02F17714BA-seeklogo.com.png",
      },
      {
        name: "Java",
        image: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/Java-Logo%20(1).png",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      },
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "C++",
        image: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/c-logo.png",
      },
     
     
    ],
  },
  {
    title: "Cybersecurity",
    skills: [
      {
        name: "CompTIA  A+ (pending - 2023)",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/download%20(3).png",
      },
      {
        name: "CompTIA  Network+ (pending -2023)",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/download%20(2).png",
      },
      {
        name: "CompTIA Security+ (pending - 2024)",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/download%20(1).png",
      },
      {
        name: "Amazon AWS Cloud Practitioner (pending - 2024) ",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/aws-cloudpractitioner-2020.png",
      },
      {
        name: "Powershell ",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/kisspng-powershell-microsoft-corporation-installation-net-meetup-logo-5b5e88b5f33711.7919684815329220379962.jpg",
      },
      {
        name: "Linux ",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/pngimg.com%20-%20linux_PNG1.png",
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://camo.githubusercontent.com/fbfcb9e3dc648adc93bef37c718db16c52f617ad055a26de6dc3c21865c3321d/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Salesforce",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/58482f67cef1014c0b5e4a81.png",
      },
      {
        name: "Five9",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/download.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Groove Click Funnel",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/j09ekAUb_400x400.jpg",
      },
      {
        name: "Adobe Photoshop",
        image:
          "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/photoshop-logo-png-transparent-background-11660903313chuw8kk4zd.png",
      },
      
    ],
  },
];


export const experiences = [
  {
    id: 0,
    img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/output-onlinepngtools_140x%402x.jpg",
    role: "Owner ",
    company: "TheG'Days",
    date: " 08/2014- present",
    desc: "Proven track record of driving substantial business growth through strategic marketing, fundraising, customer base expansion, efficient operations management, exceptional customer service, and successful brand awareness initiatives.",
    skills: [
      "Sales and Marketing Strategy",
      "Fundraising and Financial Management",
      "Customer Acquisition",
      "Leadership and Project Management",
      "Customer Service Excellence",
      "Brand Awareness",
      "Product Development ",
      "Web Development ",
      
    ],
    
  },
  {
    id: 0,
    img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/young-woman-sitting-with-laptop-isolated-on-the-white-background-illustration-self-employed-freelance-concept-vector-removebg-preview.png",
    role: "Private Client",
    company: "Self-Employed",
    date: "01/2020 - Present",
    desc: "Innovative professional with a strong client-centric approach, renowned for delivering exceptional service, crafting high-impact marketing strategies, optimizing customer onboarding processes, and spearheading the development of cutting-edge customer service solutions, resulting in remarkable increases in loyalty, retention, and revenue.",
    skills: [
      "Exceptional Sales and Marketing Strategy",
     " Unparalleled Customer Loyalty Building",
"Expertise in Onboarding Process Optimization",
"Risk Management",
   " Data Analysis and Interpretation",


    ],
   
  },
  {
    id: 1,
    img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/62c4395b2163db781514ed1e.png",
    role: "Usability Tester ",
    company: "Usertesting.com ",
    date: "01/2021 - Present",
    desc: "Skilled usability tester and UX specialist, leveraging extensive experience in conducting successful tests, identifying critical usability issues, and collaborating effectively with cross-functional teams to drive substantial improvements in user satisfaction and product quality, resulting in a 30% reduction in customer support inquiries.",
    skills: [
      "Usability Testing ",
      " User Experience Enhancement",
      "Meticulous Issue Identification and Documentation",
      "Data Analysis",
      " Product Improvement",
      
    ],
  }
  
];


export const education = [
  {
    id: 0,
    img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/Georgia-Southern-Eagles-Logo-1982.png",
    school: "Georgia Southern University, Statesboro, Georgia ",
    date: "June 2018 - May 2023",
    desc: "Completed essential coursework in Java 1 & 2 ,Calc 1 & 2, Human Communication, Technical Writing, Psychology, Advanced Excel, SQL, Probability & Statistics, and E-commerce, demonstrating a well-rounded educational background that combines communication, technical, analytical, and business skills.",
    degree: "Bachelor of Science in Information Technology- Cybersecurity",
  },
  {
    id: 1,
    img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/WGU-MarketingLogo_NATL_RGB_Color_Owl_WGU_NoTag_Stacked_7-2021.png",
    school: "Western Governors University, Salt Lake City ,Utah",
    date: "July 2023 - Expected 2024",
    desc: "Bachelor of Science in Information Technology with a concentration in Cybersecurity, gaining expertise in network security, ethical hacking, threat detection, and secure software development to protect against evolving cyber threats.",
    degree: "Bachelor of Science in Information Technology- Cybersecurity",
  },
  {
    id: 2,
    img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/programmer-coach-logo-op15jakck3dotaxv29yir8sm9u3asw0yyst8bzee7c.png",
    school: "The Programmer Coach - Intensive Full stack Developer Technical Bootcamp ",
    date: "Apr 2022 - Expected 2024",
    desc: "Pursuing a Full Stack Developer Technical Bootcamp at The Programmer Coach, currently honing skills in C# Object-Oriented Programming (OOP), SQLServer, and HTML to excel in full-stack development.",
  },
];


export const projects = [
  {
    id: 9,
    title: "Glitchguard",
    date: "Jun 2023 - present",
    description:
      "Glitch Guard is your reliable companion in the world of web development, designed to keep your websites flawless and user-friendly. This robust bug tracker empowers web developers and teams to identify, track, and resolve glitches efficiently, ensuring a seamless user experience for your visitors.",
    image:
      "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/348s.jpg",
    tags: [
      "C#",
      "ASP.NET MVC or ASP.NET Core",
      "SQL Server ",
      "Authentication and Authorization",
      "Frontend Development (HTML, CSS, JavaScript)",
      "Web Security",
      "Web Performance Optimization",
    ],
    category: "Software Applications",
    github: "https://github.com/TechnoShanice",
    webapp: "https://trackify.duckdns.org",
    member: [
      {
        name: "Shanice Shipp",
        img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/shanice.jpeg",
        linkedin: "https://www.linkedin.com/in/jane-s-41457916b/",
        github: "https://github.com/TechnoShanice",
      },
    ],
  },
  {
    id: 0,
    title: "TheGdays.com Website upgrade",
    date: "Sept 2023 - present",
    description:
      "At TheGdays.com, we believe in progress, innovation, and enhancing your online journey. We are thrilled to unveil our latest website upgrade, meticulously crafted to provide you with an unparalleled digital experience. Here's what you can expect from our transformative overhaul:",
    image:
      "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/Screenshot%202023-09-29%20203440.png",
    tags: [" Sleek and Intuitive User Interface", "Enhanced SEO Optimization", " Compelling Copyrighting and Engaging Content", "Strategic Marketing Integration", "AI Chatbot Assistance"],
    category: "software application",
    github: "https://github.com/TechnoShanice",
    webapp: "https://www.upwork.com/freelancers/~01e3b3ce4601d71ce6?viewMode=1&p=1704625075540680704",
    member: [
      {
        name: "Shanice Shipp",
        img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/shanice.jpeg",
        linkedin: "https://www.linkedin.com/in/jane-s-41457916b/",
        github: "https://github.com/TechnoShanice",
      },
      
      
    ],
  },
  {
    id: 1,
    title: "Elevate Brands: Video, 3D, & Social",
    date: "Oct 2022 - Present",
    description:
      "Designed and developed the Vexa project, a project management app that helps users and teams stay organized and on track. Implemented key features such as task tracking, team collaboration, and time tracking to improve productivity and project outcomes and also invite team/project members. The plan is to add a community of users where they can find developers and other team members and chat system implementation.",
    image:
      "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/Screenshot%202023-09-29%20191511.png",
    tags: [
      "Content Planning",  "Social Media Marketing", "Storyboarding", "Scriptwriting", "Concept Development", "Branding", "Brand Identity", "3D Animation", "Tech & IT", "3D Game Art", "Ecommerce Website", "Ecommerce"
    ],
    category: "E-commerce",
    github: "https://github.com/TechnoShanice",
    webapp: "https://www.upwork.com/freelancers/~01e3b3ce4601d71ce6?viewMode=1&p=1704621072578473984",
    member: [
      {
        name: "Shanice Shipp",
        img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/shanice.jpeg",
        linkedin: "https://www.linkedin.com/in/jane-s-41457916b/",
        github: "https://github.com/TechnoShanice",
      },
    ],
  },
  {
    id: 2,
    title: "Google Ads Appeal: Policy Compliance & Keyword Safeguarding",
    date: "Jan 2023 - Mar 2023",
    description:
      "At Google Ads Appeal, we understand the critical balance between creativity and compliance. Our team of experts is dedicated to revamping your ad campaigns, addressing policy violations, and refining your keyword strategy. Whether you've faced ad disapprovals, suspensions, or keyword restrictions, we’re here to help. We meticulously analyze your campaigns, align them with Google’s policies, and optimize your keywords to enhance your ad performance. With us, you can rest assured that your ads are not only engaging but also fully compliant, ensuring a seamless and successful advertising experience.",
    image:
      "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/Screenshot%202023-09-29%20203502.png",
    tags: ["Google Ads Expertise", "Policy Compliance", "Keyword Research", "Data Analysis", "Ad Copywriting", "Problem-Solving", "Web-Crawling"],
    category: "E-commerce",
    github: "https://github.com/TechnoShanice",
    webapp: "https://www.upwork.com/freelancers/~01e3b3ce4601d71ce6?viewMode=1&p=1704611622995525632",
    member: [
      {
        name: "Shanice Shipp",
        img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/shanice.jpeg",
        linkedin: "https://www.linkedin.com/in/jane-s-41457916b/",
        github: "https://github.com/TechnoShanice",
      },
      
    ],
  },
  {
    id: 3,
    title: "Navigating Pandemic Challenges: Importing Nebulizers Successfully",
    date: "Dec 2021 - Apr 2022",
    description:
      "In January 2021, amidst the Covid-19 pandemic, I led a vital project, guiding a client through FDA regulations and supplier evaluations. Our strategic breakthrough involved identifying an FDA-cleared nebulizer manufacturer in China and reclassifying the devices as humidifiers. This ingenuity streamlined imports, ensuring crucial medical resources reached the USA efficiently, a testament to strategic problem-solving in challenging times.",
    image:
      "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/consult.png",
    tags: ["Regulatory Compliance", "Regulatory Compliance", "Trade Law", "Risk Assessment", "Risk Analysis", "Sales", "Tech & IT", "COVID-19", "Microsoft Excel", "Ecommerce"],
    category: "E-commerce",
    github: "https://github.com/TechnoShanice",
    webapp: "https://www.upwork.com/freelancers/~01e3b3ce4601d71ce6?viewMode=1&p=1704603901087670272",
    member: [
      {
        name: "Shanice Shipp",
        img: "https://raw.githubusercontent.com/TechnoShanice/shaniceportfolio/main/uploads/shanice.jpeg",
        linkedin: "https://www.linkedin.com/in/jane-s-41457916b/",
        github: "https://github.com/TechnoShanice",
      },
    ],
  },
 
];


export const TimeLineData = [
  { year: 2014, text: "Started my journey" },
  { year: 2018, text: "Graduated Highschool, Attended Georgia Southern Summer School" },
  { year: 2020, text: "Pandemic Happened , Crypto pump, Business named changed to TheG'Days " },
  { year: 2021, text: "Attended Coding Bootcamp" },
  { year: 2023, text: "Finishing up school in Cybersecurity" },
];